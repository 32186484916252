import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import bitcoin from '../../Assets/Bitcoin.png';
import usdt from '../../Assets/Tether.png';
import Update from './Update';
import TableComponent from './ReUsableComponents/TableComponent';
import { toast } from 'react-hot-toast';
import MLCOriginal from '../../Assets/iconblue.png'

import useFormatDate from './Hooks/useFormatDate'

import html2pdf from "html2pdf.js";

const DashboardCards = () => {
    const [services, setServices] = useState([]);
    const [transactions, settransactions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get('https://main.majorlink.co/api/services/list', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(dx => {
            setServices(dx.data);
        });
    }, []);



    const formatDate = useFormatDate();

    const bitcoinData = services.find(service => service.symbol === 'BTC');
    const usdtData = services.find(service => service.symbol === 'USD');

    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    const company = {
        // Mocked for demonstration
        name: 'MAJOR LINK EX. WORLD LIMITED',
        bankname: 'Moniepoint MFB',
        banknumber: "6404342729"
        // ... other company details ...
    };

    function Receipt({ transaction, company }) {
        const downloadPDF = () => {
            const element = document.getElementById('receipt-content');

            const opt = {
                margin: 10,
                filename: `${transaction.referencenumber}.pdf`,
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 3 }, // Increases resolution
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
            };

            html2pdf().from(element).set(opt).save();
        };


        return (
            <div id="receipt-content" className='mx-4'>
                <div className='bg-[#2752E7] h-0.5 w-[100%] mt-2' />
                <div className='flex items-center justify-between mt-8'>
                    <div className='flex items-center space-x-2'>
                        <img
                            className='text-center w-7'
                            src={MLCOriginal}
                            alt='logo'
                        />
                        <h1 className='font-grifter text-lg mt-1 tracking-wide text-[#101113]'>Majorlink</h1>
                    </div>
                    <div className='space-y-2'>
                        <p className='text-sm font-aeonikregular'> {company.name}</p>
                        <p className='text-sm font-aeonikregular'> Nuban: {company.banknumber}</p>
                        <p className='text-sm font-aeonikregular'>Bank : {company.bankname}</p>
                    </div>
                </div>
                <div className='bg-[#f6f6f6] h-0.5 w-[100%] mt-14 mb-8' />
                <div className='flex items-center justify-between'>
                    <h1 className='font-aeonikregular text-sm text-[#2d2d2d]'>Receipt from Majorlink</h1>
                    <h1 className='font-aeonikregular text-sm text-[#2d2d2d]'>{formatDate(transaction.created_at)}</h1>
                </div>

                <div>
                    <h1 className='font-aeonikregular text-sm text-[#141414] mt-14'>{transaction.servicetype === "Sell" ? "Crypto Transfer" : "Bank Transfer"}</h1>
                    <h1 className='font-aeonikregular text-sm text-[#141414] mt-2'>Amount : {transaction.servicetype === "Sell" ? "₦" : "$"} {transaction.amount}</h1>
                    <h1 className='font-aeonikregular text-sm text-[#141414] mt-2'>Counterparty : MAJORLINK TECHNOLOGIES LTD</h1>
                </div>

                <div>
                    <h1 className='font-aeonikregular text-sm text-[#141414] mt-14'>Customer Information</h1>
                    <h1 className='font-aeonikregular text-sm text-[#141414] mt-2'>Full Name : {transaction.userfirstname} {transaction.userlastname}</h1>
                    <h1 className='font-aeonikregular text-sm text-[#141414] mt-2'>Email : {transaction.useremail}</h1>
                    <h1 className='font-aeonikregular text-sm text-[#141414] mt-2'>Phone : {transaction.userphonenumber}</h1>
                    <h1 className='font-aeonikregular text-sm text-[#141414] mt-2'>Wallet Address : {transaction.userwalletaddress}</h1>
                    <div>
                        <h1 className='font-aeonikregular text-sm text-[#141414] mt-6'>Customer Bank Information</h1>
                        <h1 className='font-aeonikregular text-sm text-[#141414] mt-2'>Bank Name : {transaction.userbankname}</h1>
                        <h1 className='font-aeonikregular text-sm text-[#141414] mt-2'>Bank Account : {transaction.userbankaccount}</h1>
                        <h1 className='font-aeonikregular text-sm text-[#141414] mt-2'>Bank Account Number : {transaction.userbanknumber}</h1>
                    </div>
                </div>


                <div>
                    <h1 className='font-aeonikregular text-sm text-[#141414] mt-14'>Transaction Memo</h1>
                    <h1 className='font-aeonikregular text-sm text-[#141414] mt-2'>
                        Memo: {transaction.servicetype === 'Buy'
                            ? `Purchase of ${transaction.servicename} Using Nigerian Naira `
                            : `Purchase of Nigerian Naira by Trading ${transaction.servicename}`}
                        ({transaction.referencenumber})
                    </h1>

                </div>

                <div className='mt-4'>
                    <h1 className='font-aeonikregular text-sm text-[#141414] mt-14'>Transaction Breakdown</h1>


                    <div className='flex items-center justify-between'>
                        <h1 className='font-aeonikregular text-sm text-[#141414] mt-10'>
                            {transaction.servicetype === 'Sell'
                                ? `Nigerian Naira Credit because of ${transaction.userfirstname.toUpperCase()} ${transaction.userlastname.toUpperCase()}`
                                : `${transaction.servicename} Credit Because of ${transaction.userfirstname.toUpperCase()} ${transaction.userlastname.toUpperCase()}`}
                        </h1>
                        <h1 className='font-aeonikregular text-sm text-[#141414] mt-2'> {transaction.servicetype === "Sell" ? "₦" : "$"} {transaction.amount}</h1>
                    </div>

                    <div className='bg-[#f6f6f6] h-0.5 w-[100%] mt-5' />

                    <div className='flex items-center justify-between'>
                        <h1 className='font-aeonikregular text-sm text-[#141414] mt-6'>
                            Taxes
                        </h1>
                        <h1 className='font-aeonikregular text-sm text-[#141414] mt-2'>₦0.00</h1>
                    </div>

                    <div className='bg-[#f6f6f6] h-0.5 w-[100%] mt-5' />

                    <div className='flex items-center justify-between'>
                        <h1 className='font-aeonikregular text-sm text-[#141414] mt-6'>
                            Transaction Rate
                        </h1>
                        <h1 className='font-aeonikregular text-sm text-[#141414] mt-2'>₦ {transaction.transactionrate}/$</h1>
                    </div>

                    <div className='bg-[#f6f6f6] h-0.5 w-[100%] mt-5' />

                    <div className='flex items-center justify-between'>
                        <h1 className='font-aeonikregular text-sm text-[#141414] mt-6'>
                            Total Payable in {transaction.servicetype === "Sell" ? `${transaction.servicename}` : 'Nigeiran Naira'}
                        </h1>
                        <h1 className='font-aeonikregular text-sm text-[#141414] mt-2'>{transaction.servicetype === "Buy" ? "₦" : "$"} {transaction.payableamount}</h1>
                    </div>

                </div>

                <div className='flex justify-between items-start mt-10'>
                    <h1 className='font-aeonikregular text-sm text-[#141414]'>Document ID: {transaction.receiptid}</h1>
                    <div>
                        <span className='font-aeonikregular text-sm text-[#141414] mt-10'>Email <h1 className='text-[#2752E7] underline underline-[#2752E7] cursor-pointer'>help@majorlink.co</h1> if you have any questions</span>

                        <h1 onClick={downloadPDF} className='font-aeonikregular text-sm text-[#2752E7] mt-5 cursor-pointer'>Download as PDF</h1>

                        <a href={transaction.receipturl} target="_blank">
                            <h1 className='font-aeonikregular text-sm text-[#2752E7] mt-5 cursor-pointer'>View Uploaded Receipts</h1>
                        </a>
                    </div>
                </div>
                {/* ... other company details ... */}


                {/* ... other transaction details ... */}
            </div>
        );
    }


    useEffect(() => {
        const fetchTransactions = async () => {
            setIsLoading(true);
            // Get the "user" object from local storage
            const userJson = localStorage.getItem('user');

            // If the user object is not found or doesn't have a token, log an error
            if (!userJson) {
                console.error('User object not found in local storage');
                setIsLoading(false);
                return;
            }

            const user = JSON.parse(userJson);
            const token = user.token;

            if (!token) {
                console.error('Token not found in user object');
                setIsLoading(false);
                return;
            }

            try {
                const response = await fetch('https://main.majorlink.co/api/v1/majorlink/latest/transactions', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                settransactions(data);
                setIsLoading(false);
            } catch (err) {
                setError(err.message);
                setIsLoading(false);
            }
        };

        fetchTransactions();
    }, []);

    const renderCryptoData = (cryptoData, imageSrc) => {
        if (!cryptoData) return <div>Crypto data not available</div>;
        return (
            <div className='flex items-center justify-between w-[85%]'>
                <div className='flex items-center space-x-4'>
                    <img src={imageSrc} className='w-6' />
                    <div>
                        <h1 className='font-aeonikmedium text-base'>{cryptoData.symbol}</h1>
                        <h1 className='font-aeonikregular text-sm text-[#717171] mt-1'>{cryptoData.name}</h1>
                    </div>
                </div>
                <div className='items-end justify-end flex flex-col'>
                    <h1 className='font-aeonikmedium text-lg'>${cryptoData.valueindollars || 'N/A'}</h1>
                    <div className='flex items-center'>
                        <span className='space-x-2 flex items-center'>
                            <h1 className='font-aeonikmedium text-xs text-green-600 animate-pulse'>Buying at ₦{cryptoData.buy}/$</h1>
                            <h1 className='font-aeonikmedium text-xs text-red-600 animate-pulse'>Selling at ₦{cryptoData.sell}/$</h1>
                        </span>
                    </div>
                </div>
            </div>
        );
    };


    async function updateTransactionStatus(id, status) {
        const tokenJson = localStorage.getItem('user');
        if (!tokenJson) {
            console.error('User object not found in local storage');
            return;
        }
        const user = JSON.parse(tokenJson);
        const token = user.token;

        // Start the loading toast
        const loadingToastId = toast.loading('Updating transaction...');

        try {
            const response = await fetch(`https://main.majorlink.co/api/transactions/status/${id}`, {
                method: 'PUT', // or 'PATCH', depending on your API's configuration
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status })  // Sending the new status
            });

            if (!response.ok) {
                // Use the toast ID from the loading toast to replace it with an error toast
                toast.error('Failed to update status', { id: loadingToastId });
                throw new Error('Failed to update status');
            }

            // If everything goes well, replace the loading toast with a success toast
            toast.success('Transaction updated successfully!', { id: loadingToastId });
            return await response.json();
        } catch (error) {
            // If an error occurs and it hasn't been caught by the response.ok check
            toast.error('Error updating transaction status.', { id: loadingToastId });
            console.error("Error updating transaction status:", error);
            throw error;
        }
    }


    function StatusDropdown({ status, onUpdate, transactionId }) {

        const getStatusbgColor = (status) => {
            switch (status) {
                case 'Pending':
                    return '#FFF8EB';
                case 'Success':
                    return '#F0FAF0';
                case 'Failed':
                    return '#FFF2F0';
                case 'Reversed':
                    return '#f2f2f2'; // Chose blue for reversed, you can change
                default:
                    return '#262626';
            }
        };

        const getStatustxtColor = (status) => {
            switch (status) {
                case 'Pending':
                    return '#EEA23E';
                case 'Success':
                    return '#2D8A39';
                case 'Failed':
                    return '#E2341D';
                case 'Reversed':
                    return '#000'; // Chose blue for reversed, you can change
                default:
                    return '#262626';
            }
        };


        const [showDropdown, setShowDropdown] = useState(false);
        const handleStatusClick = () => {
            setShowDropdown(!showDropdown);
        };

        const handleStatusChange = async (newStatus) => {
            setShowDropdown(false);
            try {
                // Use transactionId here
                await updateTransactionStatus(transactionId, newStatus);

                // Call the passed `onUpdate` function to update the status in the UI
                onUpdate(newStatus);
            } catch (error) {
                console.error("Failed to update the transaction status:", error);
            }
        };



        return (
            <div style={{ position: 'relative', backgroundColor: getStatusbgColor(status) }} className='items-center justify-center flex py-2 rounded-xl'>
                <div onClick={handleStatusClick} style={{ color: getStatustxtColor(status) }} >
                    {status || "N/A"}
                </div>
                {showDropdown && (
                    <div style={{ position: 'absolute', top: '100%', left: 0, border: '1px solid #ccc', borderRadius: '4px' }} className='z-10 bg-white py-3 items-start w-32'>
                        {['Pending', 'Success', 'Failed', 'Reversed'].map(statusOption => (
                            <div key={statusOption} style={{ cursor: 'pointer' }} onClick={() => handleStatusChange(statusOption)} className='hover:bg-[#f2f2f2] px-4 py-2 mt-2'>
                                {statusOption}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }


    function Modal({ isOpen, onClose, children }) {
        if (!isOpen) return null;

        return (
            <div style={{ position: "absolute", top: 0, left: 0, right: 10, bottom: 0, backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                <div style={{ width: '50%', backgroundColor: 'white', padding: '20px', borderRadius: '10px' }} className='ml-40 mt-80'>
                    {children}
                    <button onClick={onClose} className='font-aeonikregular mx-4 mt-2 cursor-pointer'>Close</button>
                </div>
            </div>
        );
    }


    function Modal({ isOpen, onClose, children }) {
        if (!isOpen) return null;

        return (
            <div style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0,0,0,0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <div style={{
                    width: '50%',
                    maxHeight: '80vh',  // Limit the height to 80% of the viewport
                    overflowY: 'auto',  // Make it scrollable
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '10px'
                }} className='ml-40 mt-20'>
                    {children}
                    <button onClick={onClose} className='font-aeonikregular mx-4 mt-2 cursor-pointer'>Close</button>
                </div>
            </div>
        );
    }



    return (
        <div className='mt-6'>
            <div className='mx-6'>
                <section className='bg-white text-black border border-[#f2f2f2] py-6 px-6 rounded-md w-[100%]'>
                    <h1 className='font-aeonikmedium text-base text-[#717171]'>Crypto Assets</h1>
                    <div className='flex items-center space-x-10 mt-6'>
                        {renderCryptoData(bitcoinData, bitcoin)}
                        <hr className='h-8 bg-[#f2f2f2] w-0.5'></hr>
                        {renderCryptoData(usdtData, usdt)}
                    </div>
                </section>

                <div>
                    <Update />
                </div>

                <div>

                    <TableComponent
                        headerName="Latest Transactions"
                        filters={[
                            {
                                key: 'status',
                                options: [
                                    { value: '', label: 'Transaction Status' },
                                    { value: 'Pending', label: 'Pending' },
                                    { value: 'Failed', label: 'Failed' },
                                    { value: 'Success', label: 'Success' },
                                    { value: 'Reversed', label: 'Reversed' }
                                ],
                                placeholder: 'Transaction Status',
                                defaultValue: ''
                            },
                        ]}

                        columns={[
                            {
                                key: 'name',
                                label: 'Name',
                                render: (item) => (
                                    <span className='flex items-center space-x-2' style={{ color: '#262626' }}>
                                        <h1>
                                            {item.userfirstname || "N/A"}
                                        </h1>
                                        <h1>
                                            {item.userlastname || "N/A"}
                                        </h1>
                                    </span>
                                )
                            },
                            {
                                key: 'email',
                                label: 'Email',
                                render: (item) => (
                                    <span style={{ color: '#262626' }}>
                                        {item.useremail || "N/A"}
                                    </span>
                                )
                            },
                            {
                                key: 'e-currency',
                                label: 'E-Currency',
                                render: (item) => <span style={{ color: '#262626' }}>{item.servicetype || "N/A"} {item.servicename || "N/A"}</span>
                            },
                            {
                                key: 'amount',
                                label: 'Amount',
                                render: (item) => <span style={{ color: '#262626' }}>{item.servicetype === "Sell" ? "₦" : "$"} {item.amount || "N/A"}</span>
                            },
                            {
                                key: 'status',
                                label: 'Status',
                                render: (item) => <StatusDropdown
                                    status={item.status}
                                    transactionId={item.id} // Passing the transaction ID
                                    onUpdate={(newStatus) => {
                                        // TODO: Update your local state to reflect this change
                                        const updatedTransactions = transactions.map(transaction =>
                                            transaction.id === item.id ? { ...transaction, status: newStatus } : transaction
                                        );
                                        settransactions(updatedTransactions);
                                    }}
                                />
                            },
                            {
                                key: 'transactiondate',
                                label: 'Transaction Date',
                                render: (item) => (
                                    <span style={{ color: '#262626' }}>
                                        {item.created_at ? formatDate(item.created_at) : "N/A"}
                                    </span>
                                )
                            },
                            {
                                key: 'action',
                                label: '',
                                render: (item) => (
                                    <span
                                        className='underline'
                                        style={{ color: '#262626' }}
                                        onClick={() => {
                                            setSelectedTransaction(item);
                                            setModalOpen(true);
                                        }}
                                    >
                                        View
                                    </span>
                                )
                            },
                            // ... add other columns as needed
                        ]}

                        data={transactions}
                        isLoading={isLoading}
                        error={error}
                    //onSelectRow={(item) => (onidselect(item), select(4))}
                    />

                </div>
            </div>
            <div className='ml-10'>
                <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
                    <Receipt transaction={selectedTransaction} company={company} />
                </Modal>
            </div>
        </div>
    );
};

export default DashboardCards;
