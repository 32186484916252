import React, { useState } from 'react';

function CreateGiftCardModal({ isOpen, onClose, onSubmit }) {
  const [name, setName] = useState('');
  const [valueindollars, setvalueindollars] = useState('');
  const [buy, setBuy] = useState('');
  const [sell, setSell] = useState('');
  const [image, setImage] = useState(null);
  const [icon, setIcon] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', name);
    formData.append('symbol', name);
    formData.append('valueindollars', valueindollars);
    formData.append('buy', buy);
    formData.append('sell', sell);
    formData.append('image', image);
    formData.append('icon', icon);

    onSubmit(formData);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-xl border border-[#f2f2f2]">
        <h2 className="text-xl mb-4 font-grifter">Create GiftCard</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 font-aeonikmedium">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 p-2 w-full border rounded-md font-aeonikmedium"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 font-aeonikmedium">Buy</label>
            <input
              type="text"
              value={buy}
              onChange={(e) => setBuy(e.target.value)}
              className="mt-1 p-2 w-full border rounded-md font-aeonikregular"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 font-aeonikmedium">Sell</label>
            <input
              type="text"
              value={sell}
              onChange={(e) => setSell(e.target.value)}
              className="mt-1 p-2 w-full border rounded-md font-aeonikregular"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 font-aeonikmedium">Value in Dollars</label>
            <input
              type="text"
              value={valueindollars}
              onChange={(e) => setvalueindollars(e.target.value)}
              className="mt-1 p-2 w-full border rounded-md font-aeonikregular"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 font-aeonikmedium">Image</label>
            <input
              type="file"
              onChange={(e) => setImage(e.target.files[0])}
              className="mt-1 p-2 w-full border rounded-md font-aeonikregular"
              required
            />
          </div>
           <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 font-aeonikmedium">Icon</label>
            <input
              type="file"
              onChange={(e) => setIcon(e.target.files[0])}
              className="mt-1 p-2 w-full border rounded-md font-aeonikregular"
              required
            />
          </div>
          <div className="flex justify-between">
            <button type="submit" className="bg-[#2752E7] text-white py-2 px-10 rounded-md font-aeonikmedium text-sm">
              Create
            </button>
            <button type="button" onClick={onClose} className="bg-red-500 text-white py-2 px-10 rounded-md font-aeonikmedium text-sm">
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateGiftCardModal;
