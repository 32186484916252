import React, { useState } from 'react';
import { GoBell } from 'react-icons/go'
import { PiUserLight } from 'react-icons/pi'
import { FiChevronDown } from 'react-icons/fi'
import { BiLogOut } from 'react-icons/bi';
import { Link, useNavigate } from 'react-router-dom';

function TopBar() {

  const [menuopen, setmenuopen] = useState(false)

  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear local storage
    localStorage.clear();

    // Redirect user to login page
    navigate('/');
  };


  return (
    <div className='py-6 px-6 border-b border-[#f2f2f2]'>
      <div className='flex justify-between items-center'>
        <h1 className='font-aeonikmedium text-sm text-[#262626]'>Admin Dashboard</h1>
        <span className='space-x-6 flex items-center'>

          <div className='bg-[#F2F2F2] relative h-8 w-8 rounded-full items-center justify-center flex cursor-pointer'>
            <span>
              <GoBell color='#808080' strokeWidth={0} size={18} />
            </span>
            <span className='inline-flex rounded-full h-2 w-2 bg-[#2752E7] absolute top-0 right-0'></span>
          </div>
          <span onClick={() => setmenuopen(!menuopen)} className='flex items-center space-x-1 cursor-pointer'>
            <div className='bg-[#F2F2F2] h-8 w-8 rounded-full items-center justify-center flex'>
              <span>
                <PiUserLight color='#808080' strokeWidth={0} size={18} />
              </span>
            </div>
            <span>
              <FiChevronDown color='#808080' />
            </span>
          </span>
        </span>
      </div>
    </div>
  )
}

export default TopBar;
