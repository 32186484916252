import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { Routes, Route, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../Components/AdminComponents/Navbar';
import DashboardCards from '../../Components/AdminComponents/DashboardCards';
import Greeting from '../../Components/AdminComponents/Greeting';
import Idle from '../../Components/AdminComponents/IdleTimerContainer';
import Image from '../../Components/AdminComponents/Image'
import Update from '../../Components/AdminComponents/Update';
import ErrorPage from '../ErrorPage';
import TopBar from '../../Components/AdminComponents/TopBar';
import DashboardCustomers from '../../Components/AdminComponents/DashboardCustomers';
import DashboardPayments from '../../Components/AdminComponents/DashboardPayments';
import DashboardServices from '../../Components/AdminComponents/DashboardServices';

const AdminDashboard = () => {

  const navigate = useNavigate();

  const [selectedRoute, setSelectedRoute] = useState('dashboard-overview'); // e.g., 'management-overview'

  const [selected, setselected] = useState(0)

  useEffect(() => {
    if (selectedRoute) {
      navigate(selectedRoute);
    }
  }, [selectedRoute, navigate]);

  return (

    <div className='flex'>
      <Helmet>
        <title>Admin Dashboard</title>
      </Helmet>
      <div className='fixed w-[23%] bg-white z-10'>
        <Navbar selected={selected} setselected={setselected} onRouteSelect={setSelectedRoute}/>
      </div>
      <div className='w-[77%] flex-1 ml-[23%]'>
        <TopBar/>
        <Routes>
          <Route path="dashboard-overview" element={<DashboardCards onRouteSelect={setSelectedRoute} setselected={setselected} />} />
          <Route path="dashboard-services" element={<DashboardServices onRouteSelect={setSelectedRoute} setselected={setselected} />} />
          <Route path="dashboard-users" element={<DashboardCustomers onRouteSelect={setSelectedRoute} setselected={setselected} />} />
          <Route path="dashboard-payments" element={<DashboardPayments onRouteSelect={setSelectedRoute} setselected={setselected} />} />
          {/* ... other nested routes */}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
      {/*
      
      <Idle></Idle>
      */}
    </div>
  )
}

export default AdminDashboard