import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import { IonIcon } from "react-ion-icon";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import BounceLoader from "react-spinners/BounceLoader";

import spiral from '../../Assets/spiral.png'


const AdminLogin = () => {

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate()

  const APIURL = ('https://main.majorlink.co/api/admin/login')
  const [email, setemail] = useState("")
  const [password, setpassword] = useState("")


  async function login() {
    const item = { email, password }
    if (email.length) {
      if (email.match(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) !== null) {
        setLoading(true)
        fetch((APIURL), {
          method: 'POST',
          body: JSON.stringify(item),
          headers: {
            "Content-Type": 'application/json'
          }
        }).then(res => res.json())
          .then(data => {
            setLoading(false)
            if (data.error === true) {
              setLoading(false)
              toast.info(data.message)
            } else {
              toast.success('success')
              setLoading(false)
              localStorage.setItem('user', JSON.stringify(data.success))
              navigate('/admin')
            }
          })

      } else {
        toast.error('Email Address Is Invalid')
        setLoading(false)
      }
    } else {

      toast.error("Email Address Is Required")
      setLoading(false)
    }
  }


  return (
    //Checkout Main Container
    <div className="flex h-screen">
   
      {/*Center Div*/}

      {/* Left side (45% width, full height, blue background) */}
      <div className="hidden md:flex md:w-[40%] bg-[#4B5DFF] h-full overflow-x-hidden">
        {/* Image in the left side */}
        <img src={spiral} alt='Spiral' className='w-40 h-40' />

        <div className='items-center justify-center absolute bottom-32 left-12 right-6 self-center'>
          <h2 className='md:text-xl text-xl font-grifter tracking-wide md:mt-20 mt-10 text-white'>Trade your GiftCards & Crypto</h2>
          <h2 className='text-[#b4bbf3] md:text-4xl text-5xl mt-4 font-grifter tracking-wide'>Easy with Speed.</h2>
          <p className='text-[#ffffff] text-base font-aeonikregular text-start mt-4 tracking-wide w-[38%]'>Start trading now to enjoy the best rates. We provide the best and
            fastest service across the world in swapping e-currencies.</p>
        </div>
      </div>
      <div className=" bg-white md:w-[60%] md:px-16 px-6 mt-10 h-screen z-10">
       
        <h2 className='text-3xl text-primary text-start font-grifter'>Welcome Back,</h2>
        <h2 className='text-base text-start font-aeonikregular'> Kindly Enter Login details to access the dashboard</h2>

        <div>
          <section className='mt-10 text-start flex flex-col'>
            <label className='text-sm font-aeonikregular text-gray-300 text-start'>Email</label>
            <input
              className='text-sm font-aeonikregular py-3 px-3 outline-none  border-[#f2f2f2] border mt-2 rounded-md'
              placeholder='email'
              type='email'
              value={email}
              onChange={(e) => setemail(e.target.value)} />
          </section>

          <section className='mt-8 text-start flex flex-col'>
            <label className='text-sm font-aeonikregular text-gray-300 text-start'>Password</label>
            <input
              className='text-sm font-aeonikregular outline-none py-3 px-3 border-[#f2f2f2] border mt-2 rounded-md'
              placeholder='******'
              type='password'
              value={password}
              onChange={(e) => setpassword(e.target.value)} />
          </section>
        </div>

        <button className='p-3 bg-[#2752E7] text-white text-sm font-aeonikmedium rounded-md w-full mt-10 cursor-pointer' onClick={login}>Log In </button>

        <div className='items-center justify-center mt-10 flex'>
          <BounceLoader color="#2752E7" loading={loading} />
        </div>
      </div>
      {/*Center Div*/}
    </div>
    //Checkout Main Container
  )
}

export default AdminLogin