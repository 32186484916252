import React, { useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';

function CustomSelect({ options, value, onChange, className, placeholder }) {
  const [isOpen, setIsOpen] = useState(false);

  const selectedOption = options.find(option => option.value === value) || {};

  return (
    <div className={`relative ${className}`}>
      <div
        className="border-[#f2f2f2] border rounded-md px-2 py-1 flex justify-between items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-aeonikregular text-xs text-[#4E4E4E]">
          {selectedOption.label || placeholder}
        </span>
        <span className='ml-2'>
          <FiChevronDown color='#4E4E4E' size={14} />
        </span>
      </div>

      {isOpen && (
        <div className="absolute w-40 mt-2 border-[#f2f2f2] border rounded-lg bg-white z-10">
          {options.map((option) => (
            <div
              key={option.value}
              className={`p-2 font-aeonikregular text-xs ${option.className || ''} cursor-pointer hover:bg-gray-200`}
              onClick={() => {
                onChange(option.value);
                setIsOpen(false);
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default CustomSelect;
