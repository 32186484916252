import React, { useState, useEffect } from 'react'
import TableComponent from './ReUsableComponents/TableComponent'
import axios from 'axios';

import useFormatDate from './Hooks/useFormatDate'
import { toast } from 'react-hot-toast';
import CreateGiftCardModal from './CreateGiftCardModal';

function DashboardServices() {
    const [crypto, setcrypto] = useState([])
    const [giftcards, setgiftcards] = useState([])
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);


    const formatDate = useFormatDate();

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        axios.get('https://main.majorlink.co/api/services/list', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(dx => {
            setcrypto(dx.data.filter(item => item.type === 'Crypto'));
            setIsLoading(false);
            setError(false)
        });
    }, []);

    const [isdLoading, setdIsLoading] = useState(true);
    const [derror, setdError] = useState(null);

    useEffect(() => {
        const fetchTransactions = async () => {
            setdIsLoading(true);
            // Get the "user" object from local storage
            const userJson = localStorage.getItem('user');

            // If the user object is not found or doesn't have a token, log an error
            if (!userJson) {
                console.error('User object not found in local storage');
                setdIsLoading(false);
                return;
            }

            const user = JSON.parse(userJson);
            const token = user.token;

            if (!token) {
                console.error('Token not found in user object');
                setdIsLoading(false);
                return;
            }

            try {
                const response = await fetch('https://main.majorlink.co/api/crypto/giftcards', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setgiftcards(data);
                setdIsLoading(false);
            } catch (err) {
                setdError(err.message);
                setdIsLoading(false);
            }
        };

        fetchTransactions();
    }, []);


    const handleUpdate = async (e) => {
        e.preventDefault();

        const updatedData = {
            buy: e.target.buy.value,
            sell: e.target.sell.value,
            valueindollars: e.target.valueindollars.value,
        };

        const loadingToastId = toast.loading('Updating service...');


        try {
            const response = await fetch(`https://main.majorlink.co/api/services/${selectedItem.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedData),
            });

            if (response.ok) {
                // Update was successful, do something like refreshing the data or closing the modal
                setIsLoading(true);
                toast.success('Service updated successfully!', { id: loadingToastId });
                axios.get('https://main.majorlink.co/api/services/list', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }).then(dx => {
                    setcrypto(dx.data.filter(item => item.type === 'Crypto'));
                    setIsLoading(false);
                    setError(false)
                });
                setModalOpen(false);
                // Optionally fetch new data or alert the user
            } else {
                // Handle error
                toast.error('Failed to update service rate', { id: loadingToastId });
            }
        } catch (error) {
            console.error('There was an error updating the item:', error);
        }
    };

    const [disModalOpen, setdModalOpen] = useState(false);

    const handleCreateGiftCard = async (formData) => {
        const API_ENDPOINT = 'https://main.majorlink.co/api/crypto/giftcard';
    
        let isLoading = true;
    
        const userJson = localStorage.getItem('user');
        if (!userJson) {
            console.error('User object not found in local storage');
            isLoading = false;
            toast.error('Authentication error. Please login again.');
            return;
        }
    
        const user = JSON.parse(userJson);
        const token = user.token;
    
        if (!token) {
            console.error('Token not found in user object');
            isLoading = false;
            toast.error('Token missing. Please login again.');
            return;
        }
        const loadingToastId = toast.loading('Creating service...');
        try {
            const response = await fetch(API_ENDPOINT, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    
            if (response.ok) {
                const data = await response.json();
                console.log("GiftCard created:", data);
                setModalOpen(false); 
                toast.success('GiftCard created successfully!', { id: loadingToastId });
            } else {
                const errorMessage = await response.text();
                console.error("Error creating GiftCard:", errorMessage);
                toast.error(`Error: ${errorMessage}`, { id: loadingToastId });
            }
        } catch (error) {
            console.error("There was an error sending the request:", error);
            toast.error('Failed to create GiftCard. Please try again.');
        }
    };
    

    return (
        <div>
            <div className='mx-6'>

                <TableComponent
                    headerName="Crypto Currencies"
                    filters={[

                    ]}

                    columns={[
                        {
                            key: 'name',
                            label: 'Crypto Name',
                            render: (item) => (
                                <span className='flex items-center space-x-2' style={{ color: '#262626' }}>
                                    <h1>
                                        {item.name || "N/A"}
                                    </h1>
                                </span>
                            )
                        },
                        {
                            key: 'buy',
                            label: 'Buy Rate',
                            render: (item) => (
                                <span style={{ color: '#262626' }}>
                                    ₦ {item.buy || "N/A"}/$
                                </span>
                            )
                        },
                        {
                            key: 'sell',
                            label: 'Sell Rate',
                            render: (item) => <span style={{ color: '#262626' }}>₦ {item.sell || "N/A"}/$</span>
                        },
                        {
                            key: 'amountindollars',
                            label: 'Amount in Naira',
                            render: (item) => <span style={{ color: '#262626' }}>₦{item.valueindollars || "N/A"}</span>
                        },
                        {
                            key: 'status',
                            label: 'Status',
                            render: (item) => <span style={{ color: '#262626' }}>{item.active === "1" ? "Active" : "InActive"}</span>
                        },
                        {
                            key: 'updateddate',
                            label: 'Updated Date',
                            render: (item) => (
                                <span style={{ color: '#262626' }}>
                                    {item.created_at ? formatDate(item.updated_at) : "N/A"}
                                </span>
                            )
                        },
                        {
                            key: 'action',
                            label: '',
                            render: (item) => (
                                <span
                                    className='underline'
                                    style={{ color: '#262626' }}
                                    onClick={() => {
                                        setSelectedItem(item);
                                        setModalOpen(true);
                                    }}
                                >
                                    View
                                </span>
                            )
                        }

                        // ... add other columns as needed
                    ]}

                    data={crypto}
                    isLoading={isLoading}
                    error={error}
                //onSelectRow={(item) => (onidselect(item), select(4))}
                />

                {isModalOpen && (
                    <div className="modal bg-white border border-[#f2f2f2] rounded-md px-6 py-4">
                        <form onSubmit={handleUpdate}>
                            <input type="hidden" value={selectedItem?.id} />
                            <h1 className='font-aeonikregular text-sm mb-6'>Update {selectedItem?.name} Rate</h1>
                            <div className='flex items-center justify-between space-x-4'>
                                <div className='flex flex-col w-[100%]'>
                                    <label className='font-aeonikregular text-sm mb-2'>
                                        Buy Rate
                                    </label>
                                    <input type="text" defaultValue={selectedItem?.buy} name="buy" className='font-aeonikregular outline-none border border-[#f2f2f2] rounded-md py-2 px-3' />
                                </div>
                                <div className='flex flex-col w-[100%]'>
                                    <label className='font-aeonikregular text-sm mb-2'>
                                        Sell Rate
                                    </label>
                                    <input type="text" defaultValue={selectedItem?.sell} name="sell" className='font-aeonikregular outline-none border border-[#f2f2f2] rounded-md py-2 px-3' />
                                </div>
                                <div className='flex flex-col w-[100%]'>
                                    <label className='font-aeonikregular text-sm mb-2'>
                                        Value in Naira
                                    </label>
                                    <input type="text" defaultValue={selectedItem?.valueindollars} name="valueindollars" className='font-aeonikregular outline-none border border-[#f2f2f2] rounded-md py-2 px-3' />
                                </div>
                            </div>
                            <div className='flex items-center justify-between mt-10'>
                                <button onClick={() => setModalOpen(false)} className='bg-red-400 text-white py-3 px-4 font-aeonikregular text-sm'>Close</button>
                                <button type="submit" className='bg-[#2752E7] text-white py-3 px-10 font-aeonikregular text-sm'>Update</button>
                            </div>
                        </form>
                    </div>
                )}


                <div className='mt-20'>
                    <div className='mb-10'>
                        <button onClick={() => setdModalOpen(true)} className="bg-[#2752E7] text-white py-2 px-12 font-aeonikregular text-sm">
                            Add GiftCard
                        </button>

                        <CreateGiftCardModal
                            isOpen={disModalOpen}
                            onClose={() => setdModalOpen(false)}
                            onSubmit={handleCreateGiftCard}
                        />
                    </div>
                    <TableComponent
                        headerName="Gift Cards"
                        filters={[

                        ]}

                        columns={[
                            {
                                key: 'image',
                                label: 'Gift Card Icon',
                                render: (item) => (
                                    <span className='flex items-center space-x-2' style={{ color: '#262626' }}>
                                      <img src={item.imageurl} alt='giftcard' className='w-20'/>
                                    </span>
                                )
                            },
                            {
                                key: 'name',
                                label: 'Gift Card Name',
                                render: (item) => (
                                    <span className='flex items-center space-x-2' style={{ color: '#262626' }}>
                                        <h1>
                                            {item.name || "N/A"}
                                        </h1>
                                    </span>
                                )
                            },
                            {
                                key: 'buy',
                                label: 'Buy Rate',
                                render: (item) => (
                                    <span style={{ color: '#262626' }}>
                                       {item.buy || "N/A"}
                                    </span>
                                )
                            },
                            {
                                key: 'sell',
                                label: 'Sell Rate',
                                render: (item) => <span style={{ color: '#262626' }}> {item.sell || "N/A"}</span>
                            },
                            {
                                key: 'valueindollars',
                                label: 'Vallue in Dollars',
                                render: (item) => <span style={{ color: '#262626' }}>${item.valueindollars || "N/A"}</span>
                            },
                            {
                                key: 'updateddate',
                                label: 'Updated Date',
                                render: (item) => (
                                    <span style={{ color: '#262626' }}>
                                        {item.updated_at ? formatDate(item.updated_at) : "N/A"}
                                    </span>
                                )
                            },
                            {
                                key: 'action',
                                label: '',
                                render: (item) => (
                                    <span
                                        className='underline'
                                        style={{ color: '#262626' }}
                                        onClick={() => {
                                            setSelectedItem(item);
                                            setModalOpen(true);
                                        }}
                                    >
                                        View
                                    </span>
                                )
                            }
                            // ... add other columns as needed
                        ]}

                        data={giftcards}
                        isLoading={isdLoading}
                        error={derror}
                    //onSelectRow={(item) => (onidselect(item), select(4))}
                    />
                </div>

            </div>
        </div>
    )
}

export default DashboardServices